import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var cards = new Swiper(".cards-carousel", {
	spaceBetween: 12,
	slidesPerView: "1.25",
	slidesOffsetBefore: 24,
	slidesOffsetAfter: 24,
	breakpoints: {
		480: {
			slidesPerView: "1.5",
		},
		768: {
			slidesPerView: "2.5",
			spaceBetween: 24,
			slidesOffsetBefore: 48,
			slidesOffsetAfter: 48,
		},
		1024: {
			slidesPerView: "3.5",
			spaceBetween: 24,
			slidesOffsetBefore: 48,
			slidesOffsetAfter: 48,
		},
		1280: {
			slidesPerView: "3.5",
			spaceBetween: 48,
			slidesOffsetBefore: 80,
			slidesOffsetAfter: 80,
		},
		1600: {
			slidesPerView: "3.75",
			spaceBetween: 48,
			slidesOffsetBefore: 112,
			slidesOffsetAfter: 112,
		},
		1920: {
			slidesPerView: 4,
			spaceBetween: 48,
			slidesOffsetBefore: 128,
			slidesOffsetAfter: 128,
		},
	},
	navigation: {
		nextEl: ".swiper-next",
		prevEl: ".swiper-prev",
	},
});

var thumbs = new Swiper(".gallery-thumbs", {
	spaceBetween: 10,
	slidesPerView: "2.5",
	freeMode: true,
	watchSlidesProgress: true,
	breakpoints: {
		768: {
			spaceBetween: 20,
			slidesPerView: 4,
		}
	}
});

var gallery = new Swiper(".gallery", {
	spaceBetween: 20,
	thumbs: {
		swiper: thumbs,
	},
});