import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const text = document.querySelector('#text-reveal');
let letters
if (text) {
  letters = text.textContent.split('');
  text.textContent = '';
}

if (letters && text) {
  letters.forEach(letter => {
    const span = document.createElement('span');
    span.textContent = letter;
    text.appendChild(span);
  });
  const tl = gsap.timeline({
    duration: 0.03,
    scrollTrigger: {
      trigger: text,
      start: "top 70%",
      markers: false
    }
  });
  
  tl.from('#text-reveal span', {
    opacity: 0,
    y: 50,
    stagger: 0.03
  });
}

